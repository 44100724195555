<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('message.client_products') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    @c-submit="sendProductsToClient()"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <el-row > 
            <el-col :span="24" v-loading="loadingData">
                <div class="gc-card__body px-3 py-4">
                    <div class="app-form__group mb-0">
                        <div class="gc-card rounded-sm mb-4 p-relative mr-3 px-3 py-3">   
                            <el-form ref="form" :model="form" :rules="client_product_rules" label-position="top">
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item
                                            prop="client_id"
                                            :label="$t('message.reciever')"
                                            >   
                                            <select-client
                                                :size="'large'"
                                                class="w-100"
                                                :placeholder="$t('message.reciever')"
                                                v-model="form.client_id"
                                                :id="form.client_id" 
                                            >
                                            </select-client>
                                        </el-form-item>     
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            prop="filial_id"
                                            :label="$t('message.to_filial')"
                                            >  
                                            <select-to-filial
                                                ref="filial" 
                                                v-model="form.filial_id" 
                                                :placeholder="$t('message.to_filial')"
                                                :id="form.filial_id" 
                                                :select_only="false"
                                                class="select__width w-100" 
                                            />
                                        </el-form-item>     
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            prop="date"
                                            :label="$t('message.date')"
                                            >  
                                            <el-date-picker
                                                v-model="form.date"
                                                type="datetime"
                                                class="w-100"
                                                :placeholder="$t('message.date')"
                                                :picker-options="datePickerOptions"
                                                :clearable="false"
                                                format="yyyy-MM-dd HH:mm"
                                                value-format="yyyy-MM-dd HH:mm"
                                                default-time="10:00">
                                            </el-date-picker>
                                        </el-form-item>     
                                    </el-col>
                                    <el-col :span="6" class="text-right">
                                        <el-button @click="makePayment()" v-if="form.client_id" style="margin-top:8px;" type="primary"> 
                                            {{ $t('message.make_a_payment') }} 
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <el-row :gutter="20" class="mm__font_bold">
                                <el-col :span="8">
                                    <span>{{ $t('message.total') }} {{ client.total_amount | formatMoney(1) }}</span>
                                </el-col>
                                <el-col :span="8">
                                    <span>{{ $t('message.paid_money') }}: {{ client.paid_money | formatMoney(1) }}</span>
                                </el-col>
                                <el-col :span="8">
                                    <span :class="client.remainder >= 0 ? 'mm__paid_color': 'mm__debt_color'">{{ $t('message.debt') }}: {{ ((client.remainder > 0) ? client.remainder : (-client.remainder)) | formatMoney(1) }}</span>
                                </el-col>
                            </el-row>
                            <div class="pt-5 mb-5 mm__collapse__custom">
                                <el-divider content-position="left"> <h3>{{ $t('message.client_products') }}</h3></el-divider>
                                <div class="mb-4 cargo__right">
                                    <el-input class="mr-5" ref="scanRef" @input="scanClientProductBarcodeDebounce" :placeholder="$t('message.scan')" v-model="barcode_search" size="mini"></el-input>
                                </div>
                                <el-collapse v-model="collapseActiveName" @change="handleCollapseChange">
                                    <el-collapse-item v-for="(deal, index) in dealProductsList" :key="index+'+key'" :name="index+'+key'">
                                        <template slot="title" >
                                            <div class="pl-3 mm__font_bold w-100" :class="(deal.paid_money - deal.total) >= 0 ? 'mm__paid__back_color' : ''">
                                                
                                                <el-tooltip class="item" effect="dark" content="В упаковке имеются грузы из других заказов. Требуется распаковать." placement="top-start">
                                                    <i v-if="deal.can_unpack" class="fa fa-exclamation-triangle mr-2 mm_warning_icon"></i>
                                                </el-tooltip>
                                                <span class="mm_color_blue" v-for="prod in deal.products">{{ prod.barcode }}</span> 
                                                <span class="ml-5">{{$t('message.deal')}} № <span class="mm_color_blue">{{deal.id}}</span></span> 
                                                <span v-if="deal.payer" class="ml-5">{{ $t('message.payer') }}: {{ deal.payer }} </span>
                                            </div>
                                        </template>
                                        <template>
                                            <el-table
                                                :data="deal.products"
                                                border 
                                                style="width: 100%"
                                                :row-class-name="tableRowClassName"
                                                >
                                                <el-table-column type="index" width="50"> </el-table-column>
                                                <el-table-column :label="$t('message.name')">
                                                    <template slot-scope="scope"> 
                                                        <span v-if="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                                        <span v-if="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="$t('message.barcode')">
                                                    <template slot-scope="scope"> 
                                                        <span>{{ scope.row.barcode }} </span>    
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="$t('message.quantity_y')">
                                                    <template slot-scope="scope"> 
                                                        <span v-if="scope.row.type === 'product'">{{ scope.row.quantity }}  </span>
                                                        <span v-if="scope.row.type === 'package'"> 1 </span>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column :label="$t('message.incoming_quantity')">
                                                    <template slot-scope="scope">
                                                        <span v-if="scope.row.type == 'product'">{{ scope.row.old_incoming_quantity }} </span>
                                                        <span v-if="scope.row.type == 'package'"> 1 </span>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column :label="$t('message.current_quantity')">
                                                    <template slot-scope="scope">
                                                        <el-input
                                                            @input="checkValue(scope.row)"
                                                            :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                                            :min="0" 
                                                            type="number" 
                                                            size="mini" 
                                                            v-model="scope.row.incoming_quantity"
                                                        ></el-input>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column :label="$t('message.remainder')">
                                                    <template slot-scope="scope">
                                                        <span v-if="scope.row.type === 'product'">{{ scope.row.old_incoming_quantity - scope.row.incoming_quantity }}  </span>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column :label="columns.weight.title">
                                                    <template slot-scope="scope">
                                                        <span v-show="scope.row.type === 'product'"> {{ showWeight(scope.row) }} </span>
                                                        <span v-show="scope.row.type === 'package'"> {{scope.row.total_weight}} </span>
                                                        {{ $t('message.kg') }}
                                                    </template>
                                                </el-table-column>
                                                
                                                <el-table-column :label="$t('message.update')" width="100">
                                                    <template slot-scope="scope"> 
                                                        <el-button v-if="scope.row.type == 'product' && scope.row.batches && scope.row.batches.length > 1"
                                                            @click="showAndUpdateBatches(scope.row, deal.id)" 
                                                            type="primary" icon="el-icon-edit" circle>
                                                        </el-button> 
                                                        <el-button v-if="scope.row.type == 'product' && scope.row.parcel"
                                                            @click="updateParcelProducts(scope.row)" 
                                                            type="primary" icon="el-icon-view" circle>
                                                        </el-button>
                                                        <el-button v-if="scope.row.type == 'package' && deal.can_unpack"
                                                            @click="unpack(scope.row)" 
                                                            type="primary" size="mini" icon="fas fa-box-open" circle>
                                                        </el-button>
                                                        <el-button v-if="scope.row.type == 'package'"
                                                            @click="showPackageProducts(scope.row)" 
                                                            type="primary" size="mini" icon="el-icon-view" circle>
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>
                                    </el-collapse-item>
                                </el-collapse>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <!-- Product partiyalar bo'yicha -->
        <el-dialog 
            :append-to-body="true"
            :title="$t('message.product_inventorization')" 
            :visible.sync="batchDialog">
            <div v-if="selectedProduct.batches">
                <el-table :data="selectedProduct.batches">
                    <el-table-column type="index" width="50"> </el-table-column>
                    <el-table-column :label="$t('message.name')">
                        <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.quantity_y')">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.current_quantity')">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkBatchValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title">
                        <template slot-scope="scope"> {{ scope.row.weight }} </template>
                    </el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                </el-table>

                <div class="mt-4 cargo__right">
                    <el-button @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- Upakovka productlari -->
        <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
            <div v-loading="loadingProducts">
                <el-table :data="containerProducts">
                    <el-table-column type="index" width="50"> </el-table-column>
                    <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment ? scope.row.comment : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                    <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                </el-table>
            </div>
        </el-dialog>

        <!-- product posilki -->
        <el-dialog 
            class="dialog__modal" 
            :title="$t('message.products')" 
            :append-to-body="true"
            :visible.sync="dialogParcelProductsList" 
            width="60%" 
            @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''">
            <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
        </el-dialog>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"  
                :visible.sync="drawerPayment" 
                ref="drawerPayment"
                size="80%"
                :append-to-body="true"
                class="body_scroll_80 bg-se"
                @opened="drawerOpened('drawerPaymentChild')"
                @closed="drawerClosed('drawerPaymentChild')"
                >
                <div>
                    <crm-payment 
                        :client_id="form.client_id"
                        :filial_id="form.filial_id"
                        :paying_deals="sendingDeals"
                        ref="drawerPaymentChild" 
                        drawer="drawerPayment"> 
                    </crm-payment>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import CrmPayment from "@/views/payments/components/crm-create";
import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { dateTime } from "@/filters/index";
import { successVoice, errorVoice } from '@/utils/audioHelper';

export default {
    mixins: [form, drawer],
    components: {
        selectFromFilial,
        selectToFilial,
        selectClient,
        CrmPayment,
        parcelProductsList
    },
    data: () => ({
        mode: true,
        checked: true,
        dealProductsList: [],
        loadingData: false,
        collapseActiveName: '0+key',
        selectedProduct: {},
        selectedDeal: null,
        batchDialog: false,
        form: {
            client_id: null,
            filial_id: null,
            date: JSON.parse(JSON.stringify(dateTime(new Date()))),
            deals: [],
        },
        barcode_search: '',
        datePickerOptions: {
            disabledDate (date) {
                return date > new Date()
            }
        },
        filial_from_back: false,
        loadingProducts: false,
        dialogProductsList: false,
        drawerPayment: false,
        sendingDeals: [],
        total_weight: 0, 
        totalQuantity: 0, 
        dialogParcelProductsList: false,
        selectedProd: {}
    }),
    created() {

    },
    watch: {
        watchClientAndFilial: {
            handler: async function(newVal, oldVal) {
                if(this.form.filial_id && this.form.client_id && !this.filial_from_back){
                    this.fetchData();
                }
            },
            deep: true,
            immediate: true
        },
        dealProductsList: {
            handler: async function(newVal, oldVal) {
                this.totalQuantity = 0;
                this.total_weight = 0;
                this.sendingDeals = [];
                this.dealProductsList.forEach((deal) => {
                    deal.products.forEach(product => {
                        if(product.type === 'product'){
                            if(product.batches && product.batches.length > 1){
                                product.batches.forEach(batch => {
                                    if(batch.incoming_quantity && parseFloat(batch.incoming_quantity) > 0 ){
                                        this.total_weight += parseFloat(batch.weight);
                                        this.totalQuantity += parseFloat(batch.incoming_quantity);
                                        if(!this.sendingDeals.includes(deal.id)){
                                            parseFloat(this.sendingDeals.push(deal.id));
                                        }
                                    }
                                });
                            }else{
                                if(product.incoming_quantity && product.incoming_quantity > 0){
                                    this.total_weight += parseFloat(product.weight);
                                    this.totalQuantity += parseFloat(product.incoming_quantity);
                                    if(!this.sendingDeals.includes(deal.id)){
                                        this.sendingDeals.push(deal.id);
                                    }
                                }
                            }
                        }
                        if(product.type === 'package'){
                            if(product.incoming_quantity && product.incoming_quantity > 0){
                                this.total_weight += parseFloat(product.total_weight);
                                this.totalQuantity += parseFloat(product.incoming_quantity);
                                if(!this.sendingDeals.includes(deal.id)){
                                    this.sendingDeals.push(deal.id);
                                }
                            }
                        }
                    });
                });
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            columns: "dealProducts/columns",
            client_product_rules: "clients/client_product_rules",
            client: "clients/model",
            clientFilialProducts: "clients/clientFilialProducts",
            containerProducts: "packageForProductCirculations/products",
        }),
        watchClientAndFilial(){
            return this.form.filial_id + this.form.client_id;
        }
    },
    methods: {
        ...mapActions({
            show: "clients/showClientProductsFromFilial",
            save: "clients/sendClientProductsFromFilial",
            emptyCortainerProducts: "packageForProductCirculations/empty",
            showProducts: "packageForProductCirculations/showProducts",
            delete: "packageForProductCirculations/destroy",
        }),
        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        calculateDebt(deal){
            let deal_debt = deal.paid_money - deal.total
            if((deal.paid_money - deal.total) < 0){
                deal_debt = (deal.total-deal.paid_money);
            }
            return deal_debt;
        },
        makePayment(){
            this.drawerPayment = true;
        },
        afterLeave(){
            this.dealProductsList = [];
            this.$refs['filial'].text = '';
            this.form = {
                client_id: null,
                filial_id: null,
                date: dateTime(new Date()),
                deals: [],
            };
        },  
        tableRowClassName({row, rowIndex}) {
            if (row.incoming_quantity && row.incoming_quantity > 0) {
                return 'mm__valid_row';
            }
            return '';
        },
        fetchData(){
            if(this.$refs['scanRef']){
                this.$refs['scanRef'].focus(); 
            }
            this.loadingData = true;  
            let barcode = JSON.parse(JSON.stringify(this.barcode_search));
            this.show({...this.form, barcode: this.barcode_search}).then(res => {
                this.loadingData = false;
                if(barcode && this.dealProductsList.length === 0){
                    this.filial_from_back = true;
                    this.$set(this.form, 'client_id', JSON.parse(JSON.stringify(this.client.id)))
                    this.$set(this.form, 'filial_id', JSON.parse(JSON.stringify(res.data.result.filial_id)))
                    setTimeout(() => {
                        this.filial_from_back = false;
                    }, 100);
                }
                this.dealProductsList = JSON.parse(JSON.stringify(this.clientFilialProducts));
                this.scanClientProductBarcodeDebounce(barcode);
            }).catch(err => {
                this.loadingData = false;
            });
        },
        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.selectedDeal = null;
            this.batchDialog = false;
        },
        showPackageProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.find(deal => deal.id === this.selectedDeal).products.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                        this.$set(el, 'incoming_quantity', total_quantity);
                    }
                });
            }
        },
        checkValue(row){
            if(row.type && row.type == 'package' && (row.incoming_quantity || row.incoming_quantity > 1)){
                this.$set(row, 'incoming_quantity', 1);
            }
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', row.remainder)
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', 0)
            }
        },
        checkBatchValue(row){
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', parseFloat(row.remainder))
            }

            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', 0)
            }
        },
        showAndUpdateBatches(row, deal_id){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
            this.selectedDeal = deal_id;
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight)
                });
                return _.round(weights, 6) ;
            }else{
                return row.weight;
            }
        },
        handleCollapseChange(val){
        },
        sendProductsToClient(){
            this.form.deals = this.dealProductsList;
            if(this.form.deals.length > 0 && this.total_weight > 0 && this.totalQuantity > 0){
                this.$refs["form"].validate((valid) => {
                    if (valid && this.form.deals.length > 0) {
                        this.loadingData = true;    
                        this.save(this.form)
                            .then(res => {
                                this.$alert(res);
                                this.parent().listChanged();
                                this.close();
                                this.loadingData = false;    
                            }).catch(err => {
                                this.loadingData = false;    
                            });
                    }
                });
            }else{
                this.$notify({
                    title: this.$t('message.product'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.check_prod_quantity_inventor')
                });
            }   
        },
        stringContainString(arr) {
            return arr[1].split('').every(function(letter) {
                return arr[0].indexOf(letter) != -1;
            });
        },
        scanClientProductBarcodeDebounce: _.debounce(
            function(val) {
            this.scanClientProductBarcode(val);
            }, 300),
        async scanClientProductBarcode(val){
            let upper_barcode = val.toUpperCase();
            if(upper_barcode && this.stringContainString([upper_barcode, 'P'])){
                if(this.dealProductsList.length === 0) await this.fetchData();
                let prod_found = false;
                let count = 0;

                for (const deals of this.dealProductsList) {
                    count ++;
                    for (const product of deals.products) {
                        if(product.batches && product.batches.length > 1){
                            for (const batch of product.batches) {
                                if(batch.barcode === upper_barcode){
                                    if(batch.incoming_quantity < parseFloat(batch.remainder)){
                                        successVoice();
                                        batch.incoming_quantity ++;
                                        product.incoming_quantity ++;
                                    }else{
                                        this.insufficiant_prod_message();
                                    }
                                    prod_found = true;
                                }
                                if(prod_found) break;
                            }
                        }else{
                            if(product.barcode === upper_barcode){
                                if(!product.incoming_quantity || product.incoming_quantity < parseFloat(product.old_incoming_quantity) || product.incoming_quantity < parseFloat(product.totalQuantity)){
                                    if(product.incoming_quantity) {
                                        successVoice();
                                        product.incoming_quantity ++;
                                    }else{ 
                                        successVoice();
                                        this.$set(product, 'incoming_quantity', 1) 
                                    }
                                }else{
                                    this.insufficiant_prod_message();
                                }
                                prod_found = true;
                            }
                        }
                        
                        if(prod_found) break;
                    }
                    if(prod_found){
                        prod_found = false;
                        break;
                    }
                    if(this.dealProductsList.length === count){
                        errorVoice();
                        this.$notify({
                            title: this.$t('message.product'),
                            type: "error",
                            offset: 130,
                            message: this.$t('message.product_not_found')
                        });
                    }
                }
                this.barcode_search = '';
            }else{
                errorVoice();
                this.barcode_search = ''
            }
        },
        insufficiant_prod_message(){
            errorVoice();
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },

        listChanged(){
            this.loadingData = true;  
            this.show({...this.form}).then(res => {
                this.loadingData = false;
                this.clientFilialProducts.forEach((deal, index) => {
                    if(this.dealProductsList.length > index){
                        this.$set(this.dealProductsList[index], 'total', deal.total);
                        this.$set(this.dealProductsList[index], 'paid_money', deal.paid_money);
                    }
                });
            }).catch(err => {
                this.loadingData = false;
            });   
        },
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },

        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },

        unpack(pack){
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this') + ' ' + this.$t('message.products_will_be_returned_back_to_sender_filial'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.loadingData = true;
                this.delete({id: pack.id, type: 'unpack', filial_id: this.form.filial_id})
                    .then(async res => {
                        this.fetchData();
                        await this.$alert(res);
                        this.loadingData = false;
                    }).catch(err => {
                        this.loadingData = false;
                    })
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
            
        },
            
    }

}
</script>

<style>
    .mm__font_bold {
        font-weight: 600;
    }
    .mm__font_14 {
        font-size: 14px;
    }
    .mm__debt_color {
        color: rgb(187, 85, 85);
    }
    .mm__white_color {
        color: rgb(255, 255, 255);
    }
    .mm__paid_color {
        color: #67C23A;
    }
    .mm__paid__back_color {
        background-color: #67C23A;
    }
    .el-table .mm__valid_row {
        background-color: rgb(151, 220, 247);
    }

    .mm__collapse__custom .el-collapse-item__header {
        line-height: 46px;
    }

    .mm__font_bold .mm_color_blue {
        color: rgb(39, 39, 247) !important;
    }
    .mm_warning_icon {
        color: rgb(253, 55, 55);
    }

</style>
