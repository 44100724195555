<template>
 <div class="card-table-header table-crm-smart custom__scroll" v-loading="loadingData" element-loading-spinner="el-icon-loading">
    <table 
    class="table-my-code table-bordered"
    :class="mode ? 'table__myday' : 'table__mynight'"
   
    >
        <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th class="w50p" v-if="columns.deal_id.show">
                    {{ columns.deal_id.title }}
                </th>

                <th v-if="columns.sender.show">
                    {{ columns.sender.title }}
                </th>
                <th v-if="columns.reciever.show">
                    {{ columns.reciever.title }}
                </th>
                <th v-if="columns.partner.show">
                    {{ columns.partner.title }}
                </th>

                <th v-if="columns.container_type_id.show">
                    {{ columns.container_type_id.title }}
                </th>

                <th v-if="columns.barcode.show">
                    {{ columns.barcode.title }}
                </th>

                <th v-if="columns.products.show">
                    {{ columns.products.title }}
                </th>

                <th v-if="columns.total_weight.show">
                    {{ columns.total_weight.title }}
                </th>

                <th v-if="columns.current_location.show">
                    {{ columns.current_location.title }}
                </th>

                <th v-if="columns.from_filial_id.show">
                    {{ columns.from_filial_id.title }}
                </th>
                <th v-if="columns.to_filial_id.show">
                    {{ columns.to_filial_id.title }}
                </th>
                
                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
                <th v-if="columns.id.show">
                    <el-input
                        clearable
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.id"
                        :placeholder="columns.id.title"
                        class="id_input"
                    ></el-input>
                </th>

                <th v-if="columns.deal_id.show">
                    <el-input
                        clearable
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.deal_id"
                        :placeholder="columns.deal_id.title"
                        class="id_input"
                    ></el-input>
                </th>
                 <th v-if="columns.sender.show">
                    <select-client
                        :size="'medium'"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.sender.title"
                        v-model="filterForm.client_id"
                    >
                    </select-client>
                </th>

                <th v-if="columns.reciever.show">
                    <select-client
                        :size="'medium'"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.reciever.title"
                        v-model="filterForm.to_client_id"
                    >
                    </select-client>
                </th>

                <th v-if="columns.partner.show">
                    <select-client
                        :size="'medium'"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.partner.title"
                        :query="{clientType_id: 1}"
                        v-model="filterForm.partner_client_id"
                    >
                    </select-client>
                </th>

                <th v-if="columns.container_type_id.show">
                    <select-container-type
                        :placeholder="columns.container_type_id.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.container_type_id"
                    ></select-container-type>
                </th>

                <th v-if="columns.barcode.show">
                    <crm-input
                        :placeholder="columns.barcode.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.barcode"
                    ></crm-input>
                </th>

                <th v-if="columns.products.show">
                </th>
                
                <th v-if="columns.total_weight.show">
                </th>
                
                <th v-if="columns.current_location.show">
                </th>

                <th v-if="columns.from_filial_id.show">
                    <select-from-filial
                        :size="'medium'"
                        :select_only="false"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.from_filial_id.title"
                        v-model="filterForm.from_filial_id"
                        >
                    </select-from-filial>
                </th>

                <th v-if="columns.to_filial_id.show">
                    <select-to-filial
                        :size="'medium'"
                        :select_only="false"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.to_filial_id.title"
                        v-model="filterForm.to_filial_id"
                    >
                    </select-to-filial>
                </th>

                <th v-if="columns.created_at.show">
                    <crm-date-picker
                        :placeholder="columns.created_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.created_at"
                    ></crm-date-picker>
                </th>

                <th v-if="columns.updated_at.show">
                    <crm-date-picker
                        :placeholder="columns.updated_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.updated_at"
                    ></crm-date-picker>
                </th>

                <th
                    class="settinW"
                    v-if="columns.settings.show"
                ></th>
            </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
            <tr v-for="pack in list" :key="pack.id" class="cursor-pointer"
             :class="pack.paid_money > 0 ? (pack.paid_money === pack.product_total ? 'mm__row__green' : 'mm__row__yellow') : ''">

                <td v-if="columns.id.show">
                    {{ pack.id }}
                </td>

                <td v-if="columns.deal_id.show">
                    <!-- {{ pack.deal_id }} -->
                    <span v-for="(deal, index) in pack.deals" :key="deal.id" v-if="pack.deals">
                        {{ deal.id }}  
                        {{ (pack.deals && pack.deals.length > 0 && pack.deals.length-1 != index) ? ',' : '' }}
                    </span>
                </td>

                <td v-if="columns.sender.show">
                    <span v-if="pack.deals && pack.deals.length > 1">
                        <span v-for="(deal, index) in filterRowData(pack.deals, 'sender_id')" :key="deal">
                            <span>{{ deal }}</span>  
                            {{ filterRowData(pack.deals, 'sender_id').length-1 != index ? ',' : '' }}
                        </span>
                    </span>
                    <span v-else>
                        {{ pack.client ? (pack.client.custom_id ? ('ID: ' + pack.client.custom_id) : '') : '' }} <br>
                        {{ pack.client ? pack.client.name : '' }} <br>
                    </span>

                </td>

                <td v-if="columns.reciever.show">
                    <span v-if="pack.deals && pack.deals.length > 1">
                        <span v-for="(deal, index) in filterRowData(pack.deals, 'reciever_id')" :key="deal">
                            <span>{{ deal }}</span>  
                            {{ filterRowData(pack.deals, 'reciever_id').length-1 != index ? ',' : '' }}
                        </span>
                    </span>
                    <span v-else>
                        <span v-if="pack.reciever">
                            {{ pack.reciever ? (pack.reciever.custom_id ? ('ID: ' + pack.reciever.custom_id) : '') : '' }} <br>
                            {{ pack.reciever ? pack.reciever.name : '' }}<br>
                        </span>
                    </span>
                </td>

                <td v-if="columns.partner.show">
                    <span v-if="pack.deals && pack.deals.length > 1">
                        <span v-for="(deal, index) in filterRowData(pack.deals, 'partner_id')" :key="deal">
                            <span>{{ deal }}</span>  
                            {{ filterRowData(pack.deals, 'partner_id').length-1 != index ? ',' : '' }}
                        </span>
                    </span>
                    <span v-else>
                        <span v-if="pack.partner">
                            {{ pack.partner ? (pack.partner.custom_id ? ('ID: ' + pack.partner.custom_id) : '') : '' }} <br>
                            {{ pack.partner ? pack.partner.name : '' }}<br>
                        </span>
                    </span>
                </td>

                <td v-if="columns.container_type_id.show">
                    {{ pack.containerType ? pack.containerType.name : '' }}
                </td>

                <td v-if="columns.barcode.show">
                    {{ pack.barcode }}
                </td>

                <td v-if="columns.products.show">
                    <span @click="showItems(pack)" style="cursor: pointer; color: #0d67c9;">
                        {{ $t('message.show') }} <span class="el-icon-arrow-right"></span>
                    </span>
                </td>

                <td v-if="columns.total_weight.show">
                    {{ (pack.total_weight).toFixed(2) + ' kg  /  ' +  (pack.total_weight * $kg_to_pound).toFixed(2) + 'lbs'}}
                </td>
                
                <td v-if="columns.current_location.show">
                    {{ pack.current_location ? pack.current_location.name : '' }}
                </td>

                <td v-if="columns.from_filial_id.show">
                    {{ pack.from_filial ? pack.from_filial.name : '' }}
                </td>

                <td v-if="columns.to_filial_id.show">
                    {{ pack.to_filial ? pack.to_filial.name : '' }}
                </td>
            
                <td v-if="columns.created_at.show">
                    {{ pack.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ pack.updated_at }}
                </td>

                <td>
                    <div class="flight__edit">
                        <a class="ml-1" @click="unpack(pack)" v-if="pack.deals && pack.deals.length > 1 && permissions.some(per => per.slug == 'packageForProductCirculations.show')">
                            <i class="fas fa-box-open"></i>
                        </a>
                        <a>
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link more_icons">
                                    <i class="fa fa-print"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" size="mini">      
                                    <el-dropdown-item :command="{action: 'barcode_with_address', model: pack}" icon="fa fa-print">       
                                        {{ $t("message.barcode_with_address") }}
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{action: 'barcode_without_address', model: pack}"  icon="fa fa-print">       
                                            {{ $t("message.barcode_without_address") }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </a>
                    </div>
                </td>
            </tr>
        </transition-group>
    </table>
    
    <div class="my___pagination">
        <crm-pagination
            :class="mode ? 'pagination__day' : 'pagination__night'"
            @c-change="updatePagination"
            :pagination="pagination"
        ></crm-pagination>
    </div>
    <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="empty()" >
        <div v-loading="loadingProducts">
            <el-table :data="products">
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column>
                <el-table-column prop="name" :label="$t('message.name')">
                    <template slot-scope="scope">
                        {{ scope.row.product ? scope.row.product.name : '' }}
                    </template>
                </el-table-column>

                <el-table-column prop="comment" :label="$t('message.comment')">
                    <template slot-scope="scope">
                        {{ scope.row.comment ? scope.row.comment : '' }}
                    </template>
                </el-table-column>

                <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
               
            </el-table>
        </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import SelectContainerType from '../../../components/inventory/select-container-type';

export default {
    name: "costTransactions",
    mixins: [list],
    components: {
        selectFromFilial,
        selectToFilial,
        selectClient,
        SelectContainerType,
    },
    props: {
        parentFilter: {
            default: Object,
        },
    },

    data() {
        return {
            createModal: false,
            loadingUpdate: false,
            updateContainer: {},
            loadingProducts: false,
            activeType: "warehouse",
            dialogProductsList: false,
        };
    },
    watch: {
        parentFilter: {
            handler: async function(newVal, oldVal) {
                if(newVal && newVal.hasOwnProperty('from_filial_id')){
                    this.filterForm.from_filial_id = newVal.from_filial_id;
                }
                if(newVal && newVal.hasOwnProperty('to_filial_id')){
                    this.filterForm.to_filial_id = newVal.to_filial_id;
                }
            },
            deep: true,
            immediate: true
      },
    },
    computed: {
        ...mapGetters({
            products: "packageForProductCirculations/products",
            list: "packageForProductCirculations/list",
            permissions: "auth/permissions",
            columns: "packageForProductCirculations/columns",
            pagination: "packageForProductCirculations/pagination",            
            filter: "packageForProductCirculations/filter",
            sort: "packageForProductCirculations/sort",
            mode: "MODE"
        }),
    },
    created() {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
        this.debouncedFetchData = _.debounce(this.fetchData, 500);
    },
    methods: {
        ...mapActions({
            updateList: "packageForProductCirculations/index",
            setPagination: "packageForProductCirculations/setPagination",
            updateSort: "packageForProductCirculations/updateSort",
            updateFilter: "packageForProductCirculations/updateFilter",
            updateColumn: "packageForProductCirculations/updateColumn",
            updatePagination: "packageForProductCirculations/updatePagination",
            show: "packageForProductCirculations/show",
            empty: "packageForProductCirculations/empty",
            delete: "packageForProductCirculations/destroy",
            refreshData: "packageForProductCirculations/refreshData",
            printPackageBarcode: "packageForProductCirculations/printPackageBarcode",
            showProducts: "packageForProductCirculations/showProducts",
        }),

        unpack(pack){
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this') + ' ' + this.$t('message.products_will_be_returned_back_to_sender_filial'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.loadingData = true;
                this.delete({id: pack.id, type: 'unpack', filial_id: pack.to_filial.id})
                    .then(async res => {
                        this.fetchData();
                        await this.$alert(res);
                        this.loadingData = false;
                    }).catch(err => {
                        this.loadingData = false;
                    })
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
            
        },

        filterRowData(deals, type) {
            let filtered = []
            if(deals && deals.length > 0){
                filtered = deals.map(el => el[type]).filter((value, index, array) => array.indexOf(value) === index);
            }
            return filtered;
        },

        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort, circulated: true};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
    
        handleClick(type) {
            this.activeType = type;
            if (this.genderstatistic) {
                this.changeStatistics();
            }
        },
        showItems(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        handleCommand({action, model}) {
            if (action === "barcode_with_address") {
                this.printBarCode(model);
            }

            if (action === "barcode_without_address") {
                this.printBarCode(model, 'without_address');
            }

            if (action === "return") {
            }
        },
        printBarCode(container, type) {
            let payload = {container_id: container.id, barcode: container.barcode}
            if(type){
                payload = {container_id: container.id, barcode: container.barcode, type: type}
            }
            this.printPackageBarcode(payload)
            .then(res => {
                const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                WinPrint.document.write(res.data);
                WinPrint.document.close();
                WinPrint.focus();
                setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            })
            .catch(err => {
                this.$alert(err);
            });
        },
    },
    
};
</script>
<style>
    .space__between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .space__between span {
        cursor: pointer;
        font-size: 18px;
    }
</style>
